.bottom-menu {
  position: absolute;
  bottom: 2ch;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  align-items: center;

  .dropdown-user-link {
    // width: 135px;
    overflow: hidden;
  }

  .dark .nav-link.dropdown-user-link {
    color: white;
  }

  .light .nav-link.dropdown-user-link {
    color: white;
  }

  .nav-link.dropdown-user-link {
    display: flex;
    text-align: right;
    gap: 5px;
  }

  .user-nav {
    flex-direction: column;
  }
}
