// Base Extended (General styles which are not added 'base styles')
@import 'base-extended';

@import 'dark-layout';

@import 'rtl';

@import 'reactstrap/index';

@import 'app-loader';

.purpleBox {
    background: #9370DB;
    color: #fff;
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    font-size: 10px;
    right: -10px;
    text-align: center;
}